export const email = 'email';
export const loading_module = 'loading_module';
export const filter = 'filter';
export const source = 'source';
export const country = 'country';
export const tags = 'tags';
export const e_mail = 'e_mail';
export const first_name = 'first_name';
export const surname = 'surname';
export const date_added = 'date_added';
export const status = 'status';
export const search = 'search';
export const active = 'active';
export const inactive = 'inactive';
export const password_text = 'password';
export const forgot_password = 'forgot_password';
export const confirm_password = 'confirm_password'
export const hidden_columns = 'hidden_columns';
export const more = 'more';
export const delete_text = 'delete_text';
export const syncronize = 'syncronize';
export const tag = 'tag';
export const select_action = 'select_action';
export const add_new_contact = 'add_new_contact';
export const edit = 'edit';
export const add_new_tag = 'add_new_tag';
export const tag_editor = 'tag_editor';
export const create = 'create';
export const create_new_tag = 'Create New Tag';
export const save = 'save';
export const active_tags = 'active_tags';
export const unsaved_modifications_message = 'unsaved_modifications_message';
export const yes = 'yes';
export const no = 'no';
export const cancel = 'cancel';
export const continue_text = 'continue';
export const are_you_sure_modifications = 'are_you_sure_modifications';
export const tag_name = 'tag_name';
export const back = 'back';
export const contact_card = 'contact_card';
export const digital_persona_data = 'digital_persona_data';
export const source_details = 'source_details';
export const static_details = 'static_details';
export const contact_details = 'contact_details';
export const history = 'history';
export const e_mail_address = 'e_mail_address';
export const updated_at = 'updated_at';
export const last_name = 'last_name';
export const manual_input = 'manual_input';
export const unsaved_modifications_message_1 = 'unsaved_modifications_message_1';
export const tag_edit_success_message = 'tag_edit_success_message';
export const are_you_sure_delete = 'are_you_sure_delete';
export const tag_delete_success_message = 'tag_delete_success_message';
export const edit_tag = 'edit_tag';
export const contact_save_success_message = 'contact_save_success_message';
export const tags_updated_success_message = 'tags_updated_success_message';
export const contact_info_update_success_message = 'contact_info_update_success_message';
export const contact_delete_success_message = 'contact_delete_success_message';
export const csv_upload = 'csv_upload';
export const file_reading_error = 'file_reading_error';
export const csv_upload_progress = 'csv_upload_progress';
export const csv_upload_success = 'csv_upload_success';
export const csv_upload_success_action = 'csv_upload_success_action';
export const login_text = 'login';
export const sign_up_text = 'sign_up';
export const reset_password_text = 'reset_password_text';
export const create_new_password = 'create_new_password';
export const email_management = 'email_management';
export const engine = 'engine';
export const logout_text = 'logout';
export const are_you_sure_logout = 'are_you_sure_logout';
export const merged_data = 'merged_data';
export const current_data = 'current_data';
export const created = 'created';
export const individual_description = 'individual_description';
export const agency_description = 'agency_description';
export const company_description = 'company_description';
export const updated_data = 'updated_data';
export const updated_tags = 'updated_tags';
export const added_tags = 'added_tags';
export const added_data = 'added_data';
export const removed_tags = 'removed_tags';
export const removed_data = 'removed_data';
export const tag_updates = 'tag_updates';
export const data_updates = 'data_updates';
export const added_tags_description = 'added_tags_description';
export const removed_tags_description = 'removed_tags_description';
export const removed_key = 'removed_key';
export const added_key = 'added_key';
export const updated_key = 'updated_key';
export const from_this = 'from_this';
export const to_this = 'to_this';
export const are_you_sure_sync = 'are_you_sure_sync';
export const sync_success = 'sync_success';
export const consent = 'consent';
export const profession = 'profession';
export const customer_type = 'customer_type';
export const sync_contacts_dialogue_title = 'sync_contacts_dialogue_title';
export const sync_contacts_step_1_description_1 = 'sync_contacts_step_1_description_1';
export const sync_contacts_step_1_description_2 = 'sync_contacts_step_1_description_2';
export const sync_contacts_step_2_description_1 = 'sync_contacts_step_2_description_1';
export const sync_contacts_step_2_description_2 = 'sync_contacts_step_2_description_2';
export const sync_contacts_step_3_description_1 = 'sync_contacts_step_3_description_1';
export const sync_contacts_step_3_description_2 = 'sync_contacts_step_3_description_2';
export const list = 'list';
export const bizmail_account = 'bizmail_account';
export const mailchimp_account = 'mailchimp_account';
export const accept_communication = 'accept_communication';
export const created_on = 'created_on';
export const role = 'role';
export const user_name = 'user_name';
export const language = 'language'
export const last_updated = 'last_updated';
export const last_update_on_dataset = 'last_update_on_dataset';
export const birth_date = 'birth_date';
export const salutation = 'salutation';
export const group = 'group';
export const title = 'title';
export const external_id = 'external_id';
export const language_code = 'language_code';
export const level_of_consent = 'level_of_consent';
export const subscriber_type = 'subscriber_type';
export const subscription_date = 'subscription_date';
export const updated_subscription_date = 'updated_subscription_date';
export const from = 'from';
export const to = 'to';
export const last_activity_at = 'last_activity_at';
export const select_all_pages = 'select_all_pages';
export const select_this_page = 'select_this_page';
export const select_none = 'select_none';
export const all = 'all';
export const add_filter = 'add_filter';
export const filter_personas = 'filter_personas';
export const clear = 'clear';
export const add_new_filter = 'add_new_filter';
export const add_predefined_filter = 'add_predefined_filter';
export const apply = 'apply';
export const apply_filters = 'apply_filters';
export const add_new_filter_to_group = 'add_new_filter_to_group';
export const detailed_view = 'detailed_view';
export const navigation = 'navigation';
export const max_contacts_sync_alert_message = 'max_contacts_sync_alert_message';
export const max_contacts_tag_alert_message = 'max_contacts_tag_alert_message';
export const max_contacts_delete_alert_message = 'max_contacts_delete_alert_message';
export const tagging_in_progress = 'tagging_in_progress';
export const refresh_history = 'refresh_history';
export const refresh_history_in_progress = 'refresh_history_in_progress';
export const seconds = 'seconds';
export const no_history_text = 'no_history_text';
export const data_centralization = 'data_centralization';
export const download = 'download';
export const marketing = 'marketing';
export const marketing_module_secondary_menu_title = 'marketing_module_secondary_menu_title';
export const marketing_module_secondary_menu_description = 'marketing_module_secondary_menu_description';
export const segment_list = 'segment_list';
export const facebook_data = 'facebook_data';
export const google_data = 'google_data';
export const published = 'published';
export const create_new_segment = 'create_new_segment';
export const download_segment = 'download_segment';
export const name = 'name';
export const persona_segments = 'persona_segments';
export const persona_parameter = 'persona_parameter';
export const nr_of_users = 'nr_of_users';
export const publish = 'publish';
export const create_filter_rules = 'create_filter_rules';
export const edit_filter_rules = 'edit_filter_rules';
export const parameter_name = 'parameter_name';
export const persona_parameters = 'persona_parameters';
export const create_persona_parameters = 'create_persona_parameters';
export const edit_persona_parameters = 'edit_persona_parameters';
export const enabled = 'enabled';
export const disabled = 'disabled';
export const max_five_segments_download_notification = 'max_five_segments_download_notification';
export const edit_filter_name = 'edit_filter_name';
export const segment_save_success_message = 'segment_save_success_message';
export const no_contacts_in_segment = 'no_contacts_in_segment';
export const create_segment_instructions = 'create_segment_instructions';
export const segment_delete_success_message = 'segment_delete_success_message';
export const enable = 'enable';
export const disable = 'disable';
export const enable_selected_contacts = 'enable_selected_contacts';
export const disable_selected_contacts = 'disable_selected_contacts';
export const delete_segment = 'delete_segment';
export const contact_status_updated_success = 'contact_status_updated_success';
export const are_you_sure_publish = 'are_you_sure_publish';
export const segment_publish_success = 'segment_publish_success';
export const reports = 'reports';
export const funnel_analysis = 'funnel_analysis';
export const campaigns = 'campaigns';
export const audiences_analysis = 'audiences_analysis';
export const audiences_performance = 'audiences_performance';
export const segment_performance = 'segment_performance';
export const source_path = 'source_path';
export const path_comparison = 'path_comparison';
export const campaign_funnel = 'campaign_funnel';
export const impression = 'impression';
export const conversion_rate = 'conversion_rate';
export const conversion_cost = 'conversion_cost';
export const reach = 'reach';
export const brand_awareness = 'brand_awareness';
export const brand_consideration = 'brand_consideration';
export const decision = 'decision';
export const interaction = 'interaction';
export const page_visits = 'page_visits';
export const video_views = 'video_views';
export const clicks = 'clicks';
export const register = 'register';
export const registrations = 'registrations';
export const registraion_rate = 'registraion_rate';
export const sessions = 'sessions';
export const engaged_sessions = 'engaged_sessions';
export const buying_customers = 'buying_customers';
export const users_that_started_to_try_out = 'users_that_started_to_try_out';
export const users_that_registered = 'users_that_registered';
export const product_category_events = 'product_category_events';
export const product_page_events = 'product_page_events';
export const product_added_to_cart_event = 'product_added_to_cart_event';
export const checkout_started_event = 'checkout_started_event';
export const channel = 'channel';
export const channel_type = 'channel_type';
export const impression_rate = 'impression_rate';
export const interactions = 'interactions';
export const interaction_rate = 'interaction_rate';
export const goals = 'goals';
export const goal_rate = 'goal_rate';
export const this_feature_is_not_available = 'this_feature_is_not_available';
export const we_will_send_you_an_email = 'we_will_send_you_an_email';
export const upgrade_your_subscription = 'upgrade_your_subscription';
export const cost = 'cost';
export const cost_share = 'cost_share';
export const total = 'total';
export const compare = 'compare';
export const comparison = 'comparison';
export const examined_period = 'examined_period';
export const reference_period = 'reference_period';
export const compare_last_week = 'compare_last_week';
export const compare_last_month = 'compare_last_month';
export const compare_last_year = 'compare_last_year';
export const compare_custom = 'compare_custom';
export const impressions = 'impressions';
export const session_rate = 'session_rate';
export const conversions = 'conversions';
export const entering_the_flow = 'entering_the_flow';
export const action = 'action';
export const set_kpis = 'set_kpis';
export const campaigns_tab = 'campaigns_tab';
export const performance = 'performance';
export const promotions_cost = 'promotions_cost';
export const interaction_cost = 'interaction_cost';
export const conversion_events = 'conversion_events';
export const conversion_value = 'conversion_value';
export const save_and_crate_gorup = 'save_and_crate_gorup';
export const finish = 'finish';
export const interaction_with_product_categories = 'interaction_with_product_categories';
export const interaction_with_product_pages = 'interaction_with_product_pages';
export const interest_of_buying_products = 'interest_of_buying_products';
export const intention_of_buying_products = 'intention_of_buying_products';
export const kpi_set = 'kpi_set';
export const current_results = 'current_results';
export const no_funnels_to_present = 'no_funnels_to_present';
export const of = 'of';
export const pages = 'pages';
export const page = 'page';
export const result = 'result';
export const kpi = 'kpi';
export const achieved = 'achieved';
export const set_campaign_kpis = 'set_campaign_kpis';
export const views = 'views';
export const kpi_set_success_message = 'kpi_set_success_message';
export const sync_segments_dialogue_title = 'sync_segments_dialogue_title';
export const sync_segments_step_1_description_1 = 'sync_segments_step_1_description_1';
export const sync_segments_step_1_description_2 = 'sync_segments_step_1_description_2';
export const sync_segments_step_2_description_1 = 'sync_segments_step_2_description_1';
export const sync_segments_step_2_description_1_mailchimp = 'sync_segments_step_2_description_1_mailchimp';
export const select_google_ads_account = 'select_google_ads_account';
export const select_mailchimp_account = 'select_mailchimp_account';
export const spent = 'spent';
export const target = 'target';
export const ok = 'ok';
export const sync_complete = 'sync_complete';
export const sync_complete_description_1 = 'sync_complete_description_1';
export const new_mailchimp_audience_popup_description = "new_mailchimp_audience_popup_description";
export const sync_complete_description_2 = 'sync_complete_description_2';
export const sync_complete_description_3 = 'sync_complete_description_3';
export const sync_complete_description_4 = 'sync_complete_description_4';
export const please_add_a_comparison_date = 'please_add_a_comparison_date';
export const audience_lists = 'audience_lists';
export const audience_size = 'audience_size';
export const audience_name = 'audience_name';
export const use_this_audience = 'use_this_audience';
export const sucessfull_audience = 'sucessfull_audience';
export const sucessfull_overwrite_audience = 'sucessfull_overwrite_audience';
export const error_audience = 'error_audience';
export const use_existing_list = 'use_existing_list';
export const use = 'use';
export const overwrite_list = 'overwrite_list';
export const segment_personas = 'segment_personas';
export const list_size = 'list_size';
export const email_address = 'email_address';
export const path = 'path';
export const frequency = 'frequency';
export const interaction_path = 'interaction_path';
export const journeys = 'journeys';
export const source_path_journey = 'source_path_journey';
export const source_path_journey_text = 'source_path_journey_text';
export const top_source_paths = 'top_source_paths';
export const top_source_paths_text = 'top_source_paths_text';
export const digital_personas = 'digital_personas';
export const persona_engager = 'persona_engager';
export const type = 'type';
export const cx_status = 'cx_status';
export const customer_category = 'customer_category';
export const audience_category = 'audience_category';
