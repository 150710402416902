import { FC, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, RouteProps, useHistory } from 'react-router-dom';

import { RootReducerState } from 'Redux/Reducers/types';
import { resetLogoutUrl } from 'Redux/Actions';
import { UserState } from 'Redux/Reducers/UserReducer/types';
import { BASE_MODULE_PATH } from 'Constants/Options';
import { loginPath, signUpPath, userOnboardingPath } from 'Constants/NavigationConstants';
import WebService from 'Services/WebService/WebService';
import { IUserOnboardingReducerListState } from 'Modules/UserOnboarding/Redux/Reducers/types';
import { getintegratedPlatformsList } from 'Modules/UserOnboarding/Redux/Actions/IntegratedPlatformsActions';

const ForwardedRoute: FC<RouteProps> = (props) => {
  const userState = useSelector<RootReducerState, UserState>(state => state.user);
  const integratedPlatformsState = useSelector<RootReducerState, IUserOnboardingReducerListState>((state) => state.userOnboarding.integratedPlatforms);
  const history = useHistory();
  const dispatch = useDispatch();

  // Ref to track if platforms have been fetched
  const hasFetchedPlatforms = useRef(false);  // <-- UseRef to track if fetch was triggered
  
  // Token frissítés, ha lejárt
  useEffect(() => {
    const refreshToken = async () => {
      try {
        WebService.shared().acquireTokenSilent();
      } catch (error) {
        console.error(error);
      }
    };
    if (userState.credentials.isValidToken === false) {
      refreshToken();
    }
  }, [userState.credentials.isValidToken]);

  

  useEffect(() => {
    const fetchIntegratedPlatforms = () => {
      if (!integratedPlatformsState.data.length && !hasFetchedPlatforms.current) {  // <-- Check if platforms have been fetched
        hasFetchedPlatforms.current = true;  // <-- Set to true to prevent further calls
        setTimeout(() => {
          dispatch(
            getintegratedPlatformsList(
              (response) => {
                if (response.length >= 2 && response.some(item => item.integrationPlatform.platform === 'google_analytics')) {
                  history.replace(BASE_MODULE_PATH);
                } else {
                  history.replace(userOnboardingPath);
                }
              },
              (error) => {
                console.error("Error while fetching platforms: ", error);
              }
            )
          );
        }, 200); 
      } else {
        history.replace(BASE_MODULE_PATH);
      }
    };
    if (
      (history.location.pathname === loginPath || history.location.pathname === signUpPath) &&
      userState.credentials &&
      userState.credentials.accessToken &&
      userState.credentials.isValidToken !== false
    ) {
      if (userState.logoutUrl !== '') {
        history.replace(userState.logoutUrl);
        dispatch(resetLogoutUrl());
      } else {
        fetchIntegratedPlatforms();
      }
    }
  }, [
    dispatch,
    history,
    userState.credentials,
    userState.logoutUrl,
    integratedPlatformsState.data.length,
  ]);

  return <Route {...props} />;
};

export default ForwardedRoute;
